import axios from "axios";
import React, { useEffect, useState } from "react";
import { isAuthenticated } from "../../components/Auth/auth";
import { ErrorMessage } from "../../components/Messages/messages";
import "./Results.css";

export const Results = (props) => {
  const [data, setData] = useState([]);
  const [latestCalculation, setLatestCalculation] = useState({});

  const [showToolingCostDetails, setShowToolingCostDetails] = useState(false);
  const [showPartCostDetails, setShowPartCostDetails] = useState(false);
  const [showFixturingCostDetails, setShowFixturingCostDetails] =
    useState(false);

  const [selectedRow, setSelectedRow] = useState("");
  const handleRowClick = (id) => {
    // If the clicked row's ID is the same as the currently selected row, deselect it.
    // Otherwise, select the clicked row.
    if (selectedRow === id) {
      setSelectedRow(""); // Or use an empty string '', depending on how you've initialized your state
    } else {
      setSelectedRow(id);
    }
  };

  const getData = async () => {
    await axios
      .get(
        `https://api.castingcostadvisor.com/api/calculations/get/${isAuthenticated()._id}`,
        // `http://localhost:8000/api/calculations/get/${isAuthenticated()._id}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLatestCalculation(res.data[res.data.length - 1]);
        } else {
          ErrorMessage(res.data.errorMessage);
        }
      });
  };

  const returnToHomepage = (e) => {
    e.preventDefault();
    if (selectedRow) {
      window.open(`/?id=${selectedRow}`, "_blank");
    } else {
      props.history.push("/");
    }
  };

  console.log(data);
  console.log(latestCalculation);
  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="Results">
      <div>
        <h2 className="mb-0 display-3 text-center text-primary title">
          CURRENT COSTS BREAKDOWN
        </h2>
        <br></br>
        <div className="d-flex justify-content-center gap-4">
          <div>
            <b>Part Weight: </b>
            {latestCalculation.partWeight}
          </div>
          <div>
            <b>Part Volume: </b>
            {latestCalculation.calculatedPartVolume}
          </div>
          <div>
            <b>Metal Loss: </b>1.05
          </div>
        </div>
        <br></br>
        <div className="table-responsive">
          <table className="table table-bordered border-primary table-striped table-light">
            <thead class="thead-dark">
              <tr>
                <th className="left-col" scope="col">
                  Cost type
                </th>
                <th className="right-col" scope="col">
                  Cost amount in USD
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Total Part Cost
                  <span
                    className="expand-icon"
                    onClick={() => setShowPartCostDetails(!showPartCostDetails)}
                  >
                    {showPartCostDetails ? "-" : "+"}
                  </span>
                </td>
                <td>{latestCalculation.calculatedTPC}</td>
              </tr>
              {showPartCostDetails && (
                <>
                  <tr>
                    <td>Processing Cost</td>
                    <td>{latestCalculation.calculatedPC}</td>
                  </tr>
                  <tr>
                    <td>Material Cost</td>
                    <td>{latestCalculation.calculatedMC}</td>
                  </tr>
                  <tr>
                    <td>NDT Cost</td>
                    <td>{latestCalculation.calculatedNDTC}</td>
                  </tr>
                </>
              )}

              <tr>
                <td>
                  Total Tooling Cost
                  <span
                    className="expand-icon"
                    onClick={() =>
                      setShowToolingCostDetails(!showToolingCostDetails)
                    }
                  >
                    {showToolingCostDetails ? "-" : "+"}
                  </span>
                </td>
                <td>{latestCalculation.calculatedTC}</td>
              </tr>
              {showToolingCostDetails && (
                <>
                  <tr>
                    <td>Tooling Material Cost</td>
                    <td>{latestCalculation.calculatedTMC}</td>
                  </tr>
                  <tr>
                    <td>Part Env. Cavity Machining Cost</td>
                    <td>{latestCalculation.calculatedCPV}</td>
                  </tr>
                  <tr>
                    <td>Core Production Cost</td>
                    <td>{latestCalculation.calculatedCPC}</td>
                  </tr>
                  <tr>
                    <td>Rough Removal Cost</td>
                    <td>{latestCalculation.calculatedCRR}</td>
                  </tr>
                </>
              )}
              <tr>
                <td>
                  Fixturing Cost
                  <span
                    className="expand-icon"
                    onClick={() =>
                      setShowFixturingCostDetails(!showFixturingCostDetails)
                    }
                  >
                    {showFixturingCostDetails ? "-" : "+"}
                  </span>
                </td>
                <td>{latestCalculation.calculatedFC}</td>
              </tr>
              {showFixturingCostDetails && (
                <>
                  <tr>
                    <td>Straightening Fixture Cost</td>
                    <td>{latestCalculation.calculatedSFC}</td>
                  </tr>
                  <tr>
                    <td>Check Fixture Cost</td>
                    <td>{latestCalculation.calculatedCFC}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="mb-3 history-header">
          <h2 className="history-label">History </h2>
          <button
            type="button"
            className="btn btn-primary w-25 h-100 populate-btn"
            onClick={returnToHomepage}
          >
            Populate Data
          </button>
        </div>
        <div className="table-responsive scrollable-table">
          <table className="table table-bordered border-primary table-light">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Part Number</th>
                <th scope="col">Part Material</th>
                <th scope="col">Grade</th>
                <th scope="col">Class</th>
                <th scope="col">Part Volume (cubic inches)</th>
                <th scope="col">Part Weight (lbs)</th>
                <th scope="col">Part Complexity</th>
                <th scope="col">Total Part Cost ($)</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length > 1 &&
                data
                  .slice(0, -1)
                  .reverse()
                  .map((d, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(d._id)}
                        className={selectedRow === d._id ? "selected-row" : ""}
                      >
                        <td scope="row">{index + 1}</td>
                        <td>{d.partNumber}</td>
                        <td>{d.partMaterial}</td>
                        <td>{d.grade}</td>
                        <td>{d.partClass}</td>
                        <td>{d.calculatedPartVolume}</td>
                        <td>{d.partWeight}</td>
                        <td>{d.partComplexity}</td>
                        <td>{d.calculatedTPC}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>

        <div className="row my-3 mt-4">
          <button
            type="button"
            className="btn btn-primary w-100 mt-4"
            onClick={returnToHomepage}
          >
            Return to homepage
          </button>
        </div>
      </div>
    </div>
  );
};
